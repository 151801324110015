// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-widget-plan-list {
    .row {
        //justify-content: center;
    }

    .aioa_dashboard-plan-list-toggle {
        display: flex;
        align-items: center;
        margin-bottom: 1.8rem;
        margin-top: 1rem;

        .form-switch {
            padding-left: 3em;
            margin-left: 1rem;
            margin-right: 1rem;

            .form-check-input {
                margin-top: 0;
                margin-left: -3rem;
            }
        }

        .toggle-label {
            &.active {
                color: #913BEF;

                .discount {
                    opacity: 1;
                }
            }

            .discount {
                background-color: #913BEF;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                border-radius: 30px;
                padding: 5px 10px;
                font-size: 0.875rem;
                font-weight: 500;
                margin-left: 5px;
                opacity: .5;
            }
        }

        /* ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            border: 1px solid rgba($primary, 0.5);
            border-radius: 5px;

            li {
                padding: 0.5rem;
                background-color: rgba($primary, 0.1);

                .btn:not(.active) {
                    background-color: transparent;
                    color: $black;
                    border-color: transparent;
                    font-weight: $font-weight-normal;
                }
            }
        } */
    }

    .aioa_dashboard-widget-plan-box {
        &.card {
            --bs-card-cap-padding-y: 1.5rem;
            --bs-card-cap-padding-x: 1.5rem;
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-border-radius: 1rem;
            --bs-card-inner-border-radius: 1rem;
            --bs-card-border-width: 0;
            //box-shadow: 0 10px 0px $primary;
            position: relative;
            margin-bottom: 10px;
            border: 1px solid #8502F7;
            box-shadow: none;


            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0 3px 6px rgba($black, 0.15);
                border-radius: 1rem;
                border: 1px solid rgba($black, 0.05);
            }

            &.active {
                background: linear-gradient(36deg, #420083 1.89%, #913BEF 155.48%);
                color: $white;

                .card-header {
                    background: none;
                    border: none;
                }

                .card-body {
                    color: inherit;
                    padding-top: 0;

                    .aioa_dashboard-widget-plan-box-description {
                        color: inherit;
                    }
                    .aioa_dashboard-widget-plan-box-btn{
                        margin-top: 1.5rem;
                    }
                }
            }

            &.current {
                .card-header {
                    .aioa_dashboard-widget-plan-box-name .status {
                        display: block;
                    }
                }
            }

            .card-header {
                position: relative;
                border: 1px solid #6410C1;
                background: linear-gradient(36deg, #420083 1.89%, #913BEF 155.48%);
                color: $white;

                .aioa_dashboard-widget-plan-box-name {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    line-height: 155%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .status {
                        border-radius: 30px;
                        padding: 2px 10px;
                        font-size: 16px;
                        font-weight: normal;
                        color: white;
                        display: none;

                        &.active {
                            background: #219729;
                        }

                        &.expired,
                        &.cancelled {
                            background-color: #F53C3C;
                        }

                    }
                }

                .aioa_dashboard-widget-plan-box-price {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 145%;

                    span {
                        font-size: 18px;
                        font-weight: normal;
                    }


                    /* .aioa_dashboard-widget-plan-box-strike-price {
                        font-size: 1.125rem;
                        font-weight: $font-weight-medium;
                        color: $danger;
                    } */

                    /* .aioa_dashboard-widget-plan-box-offer-price {
                        font-size: 1.25rem;
                        font-weight: $font-weight-bold;
                        color: $primary;
                    } */


                }

                .aioa_dashboard-widget-plan-box-renewal-badge {
                    font-size: 14px;
                    line-height: 24px;
                    color: $black;
                    padding: 3px 12px;
                    background-color: #DBFFDD;
                    margin-top: 10px;
                    border-radius: 20px;
                    //text-align: center;
                    font-weight: normal;
                }

                .aioa_dashboard-widget-plan-box-divider {
                    height: 1px;
                    background: $white;
                    opacity: 0.5;
                    margin-top: 18px;
                    //margin-bottom: 18px;
                }
            }

            .card-body {
                position: relative;
                z-index: 1;
                padding: 30px 20px 20px;

                .aioa_dashboard-widget-plan-box-description, .aioa_dashboard-widget-plan-domain-description, .aioa_dashboard-widget-plan-domain-description{
                    padding: 0 20px;
                }

                .aioa_dashboard-widget-plan-box-description {                    
                    color: #000154;
                }

                .aioa_dashboard-widget-plan-domain-description {
                    margin-top: 0.5rem;
                }



                .aioa_dashboard-widget-plan-box-btn {
                    margin-top: 30px;

                    .btn {
                        &.btn-lg {
                            --bs-btn-border-radius: 10px;
                            --bs-btn-padding-x: 2rem;
                            color: $white;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 155%;

                            &.btn-primary {
                                background-color: #8502F7;

                                &:hover,
                                &:focus {
                                    background-color: #420083;
                                    color: $white;
                                }
                            }

                            &.btn-danger {
                                background-color: #F53C3C;

                                &:hover,
                                &:focus {
                                    background-color: #ffffff;
                                    color: #420083;
                                }
                            }

                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.aioa_dashboard-cancel-plan-offer {
    text-align: center;

    .aioa_dashboard-offer-image-wrapper {
        width: 500px;
        margin-inline: auto;
        height: 480px;
        overflow: hidden;
    }

    .aioa_dashboard-offer-price-wrapper {
        .price {
            font-size: 1.5rem;

            .offer-price {
                margin-inline-start: 0.8rem;
                color: $primary;
            }
        }
    }

    .aioa_dashboard-cancel-offer-btn {
        .btn {
            --bs-btn-color: var(--bs-gray-500);
            font-weight: $font-weight-normal;
            letter-spacing: 0;
        }
    }

    .aioa_dashboard-accept-offer-btn {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }
}
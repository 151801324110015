// Configuration
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/variables-dark";
@import "../bootstrap/maps";
@import "../bootstrap/mixins";
@import "../bootstrap/utilities";

.aioa_dashboard-page-title-wrapper {
    margin-bottom: 1.5rem;
    border-radius: 6px;
    background: linear-gradient(78deg, #D5D1FD 10.33%, #EDEBFF 121.25%);
    padding: 20px 30px;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .aioa_dashboard-page-title {

        .subtitle {
            font-weight: normal;
            margin-top: 0.5rem;
            line-height: 175%;
        }
    }
}